/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react';
import { Box, Spinner } from '@components/Primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { ContentPageLayout } from '@components/Layout';
import { activateUser, login } from '../../services/api';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { Link } from 'gatsby';
import { PATHS } from '../../constants/nav-items';

interface ActivatePageProps {
  location: any;
}

const ActivatePage: FC<ActivatePageProps> = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const { code } = queryString.parse(location.search);
    async function activate() {
      setLoading(true);
      try {
        await activateUser(code as string);
        setError(false);
        setLoading(false);
      } catch {
        setError(true);
        setLoading(false);
      }

    }

    if (code) activate();
  }, [location.search]);

  return (
    <ContentPageLayout twoColumnLayout>
      <Box pl="2" pr="2">
        {loading && <Spinner color="mainBlue" />}
        {!loading && error && <M id="pages.register.activateError" />}
        {!loading && !error &&
          <M
            id="pages.register.activateSuccess"
            values={{
              link: (
                <Link to={PATHS.auth.login}>
                  <M id="pages.login.login" />
                </Link>
              ),
            }}
          />}
      </Box>
    </ContentPageLayout>
  );
}

export default ActivatePage;